import { CreateControllerFn, ControllerParams } from '@wix/yoshi-flow-editor';
import { createEventHandler } from '@wix/yoshi-flow-editor/tpa-settings';

import {
  ButtonState,
  ISettingsEvents,
} from '../ChallengesList/Widget/settingsEvents';
import { initApi } from '../../services/initApi';
import { providersPropsMap } from '../../contexts/main/providersPropsMap';
import { ScreenNames } from '../../contexts/BI/interfaces';
import { challengesListDataProviderPropsMap } from '../../contexts/ChallengesListDataProvider/challengesListDataProviderPropsMap';
import get from 'lodash/get';

const createController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
}: ControllerParams) => {
  const { setProps } = flowAPI.controllerConfig;
  const publicData = controllerConfig.config.publicData.COMPONENT || {};

  initApi(flowAPI);

  // settings panel pub/sub https://github.com/wix-private/tpa-settings#events-between-settings-panel-and-applications
  const settingsEventsHandler = createEventHandler<ISettingsEvents>(publicData);
  // subscribe to event. Supports Typescript and events keys maybe only the keys of IEvents interface.
  settingsEventsHandler.on('buttonState', (value) => {
    setProps({
      buttonState: value,
    });
  });
  // subscribe on reset. When setting panel is closed
  settingsEventsHandler.onReset(() => {
    setProps({
      buttonState: ButtonState.Default,
    });
  });

  return {
    async pageReady() {
      const initialProps = await providersPropsMap({
        biSettings: {
          screenName: ScreenNames.ChallengesListPage,
          preventAutomaticallySendChallengeId: true,
          getItemsCount: async () => {
            let challenges;
            try {
              challenges = await challengesListDataProviderPropsMap(flowAPI);
            } catch (error) {
              console.error(
                '[challenges] Error getting challengesList on init BI',
                error,
              );
            }

            return get(challenges, 'challengesListData.totalCount') || 0;
          },
        },
        flowAPI,
        enabledProviders: ['challengesListDataProvider', 'paidPlansProvider'],
      });

      try {
        flowAPI.controllerConfig.setProps({
          ...initialProps,
        });
      } catch (e) {
        console.error('[challenge list]: couldnt set initial props', e);
      }

      flowAPI.controllerConfig.setProps({
        editorLoaded: true,
      });
    },
    updateConfig($w, config) {
      // notify (events should be fired)
      settingsEventsHandler.notify(config.publicData.COMPONENT || {});
    },
  };
};

export default createController;
